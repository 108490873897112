<template lang="pug">
.header_container
  .header_logo
    router-link(:to="{ name: 'Login' }", exact) #[img(src="../assets/images/logo1.svg", alt="logo")]
    img(src="../assets/images/logo2.svg", alt="logo")
  .header_links
    a(href='/legal/Aviso_privacidad_promocion.pdf' target='_blank') {{ $t('header.privacy') }}
    a(href='/legal/Bases_y_condiciones.pdf' target='_blank') {{ $t('header.bases') }}
  .header_links_nav
    button(@click="show_nav") 
      img(src="../assets/images/lista.svg", alt="menu")
    div(v-bind:class="nav_class", @click="show_nav")
      a(href='/legal/Aviso_privacidad_promocion.pdf' target='_blank') {{ $t('header.privacy') }}
      a(href='/legal/Bases_y_condiciones.pdf' target='_blank') {{ $t('header.bases') }}
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      nav_class: "header_nav_hide",
    };
  },
  methods: {
    show_nav() {
      this.nav_class === "header_nav_hide"
        ? (this.nav_class = "header_nav_show")
        : (this.nav_class = "header_nav_hide");
    },
  },
};
</script>

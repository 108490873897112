import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const windowWidth = window.innerWidth

export default new Vuex.Store({
  state: {
    name: null,
    email: null,
    phone: null,
    ticket: null,
    score: null,
    lifes: null,
    isGuest: false,
    gameOver: false,
    isMobile: windowWidth <= 600
  },
  mutations: {
    SET_DATA(state, user) {
      state.name = user.name
      state.email = user.email
      state.phone = user.phone
      state.ticket = user.ticket
      state.isGuest = false
    },
    SET_GUEST(state) {
      state.name = null
      state.email = null
      state.phone = null
      state.ticket = null
      state.isGuest = true
    },
    SET_SCORE(state, score) {
      state.score = score
    },
    SET_LIFES(state, lifes) {
      state.lifes = lifes
    },
    SET_GAME_OVER(state, gameOver) {
      state.gameOver = gameOver
    },
    RESET_STORE(state) {
      state.name = null
      state.email = null
      state.phone = null
      state.ticket = null
      state.score = null
      state.lifes = null
      state.gameOver = false
    },
    RESET_GAME(state, { points, lifes }) {
      state.score = points
      state.lifes = lifes
      state.gameOver = false
    }
  },
  actions: {},
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login_container"},[_c('div',{staticClass:"switch"},[_c('h1',[_vm._v(_vm._s(_vm.$t("login.h1")))]),_c('p',[_vm._v(_vm._s(_vm.$t("login.p"))+" "),_c('a',{attrs:{"href":"/legal/Bases_y_condiciones.pdf","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("login.link")))])])]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var errors = ref.errors;
var error = ref.error;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.next)}}},[_c('validation-provider',{attrs:{"rules":"required|alpha_spaces","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],class:{ invalid: dirty && !valid },attrs:{"type":"text","placeholder":_vm.$t("login.name")},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|email","name":"correo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],class:{ invalid: dirty && !valid },attrs:{"type":"text","placeholder":_vm.$t("login.email")},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|numeric|length:10","name":"telefono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],class:{ invalid: dirty && !valid },attrs:{"type":"text","placeholder":_vm.$t("login.phone")},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|alpha_num|length:20","name":"ticket"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ticket),expression:"ticket"}],class:{ invalid: dirty && !valid },attrs:{"type":"text","placeholder":_vm.$t("login.ticket")},domProps:{"value":(_vm.ticket)},on:{"input":function($event){if($event.target.composing){ return; }_vm.ticket=$event.target.value}}})]}}],null,true)}),_c('validation-provider',{staticClass:"bases",attrs:{"rules":{ required: { allowFalse: false } },"name":"términos y condiciones"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.terms),expression:"terms"}],attrs:{"type":"checkbox","id":"bases"},domProps:{"checked":Array.isArray(_vm.terms)?_vm._i(_vm.terms,null)>-1:(_vm.terms)},on:{"change":function($event){var $$a=_vm.terms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.terms=$$a.concat([$$v]))}else{$$i>-1&&(_vm.terms=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.terms=$$c}}}}),_c('label',{attrs:{"for":"bases"}},[_vm._v(_vm._s(_vm.$t("login.bases")))])]),_c('div',_vm._l((_vm.parseErrors(errors)),function(error){return (_vm.parseErrors(errors))?_c('p',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(error))]):_vm._e()}),0),_c('button',{attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t("login.button")))])],1)]}}])}),_c('button',{staticClass:"invited",attrs:{"type":"submit"},on:{"click":_vm.invited}},[_vm._v(_vm._s(_vm.$t("login.invited")))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
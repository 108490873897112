<template lang="pug">
.over_container
  h1 {{ $t('over.h1') }} <br> Tu puntuación fue: {{score}}
  h2 {{ $t('over.h2') }}
  button(@click="next") {{ $t('over.button') }}
</div>
</template>

<script>
import store from '@/store'
import {mapState, mapMutations} from 'vuex' 

export default {
  name: "Over",
  methods: {
    ...mapMutations(['RESET_GAME']),
    next() {
      this.RESET_GAME({ points: 0, lifes: 4 })
      return this.$router.push({ name: "PinBall" })
    }
  },
  computed: {
    ...mapState(['isGuest', 'score'])
  },
  async mounted() {
          if(this.isGuest) {
            console.log('Guest game... we are hiring... contacto @ torque.studio')
            return
            }

    const { isGuest, lifes, gameOver, isMobile, ...params} = store.state
    try {
        const response = await this.$api.savePlayer(params)
    } catch(error) {
      console.log(error)
    }
  }
}
</script>

<template lang='pug'>
#app.container
  Header
  router-view
</template>

<script>
import Header from "./components/Header";

export default {
  components: {
    Header,
  }
};
</script>

<style lang="scss">
@import './styles/index.scss';
</style>

<template lang="pug">
  div  
    .board(v-if="downloaded")
      h1.lifes.d-inline(:class="{isMobile}") Vidas restantes: {{ lifes }}
      h1.score(:class="{isMobile}") Puntos: {{ score }}
    div(:id="containerId" v-if="downloaded")
    .placeholder(v-else) Cargando ...
    .info(v-if="downloaded")
      h1.controls {{ $t('instructions.h2.1') }} #[img(src="../assets/images/ArrowCircleLeft.svg", alt="left")] #[img(src="../assets/images/ArrowCircleRight.svg", alt="right")] {{ $t('instructions.h2.2') }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Game',
  data() {
    return {
      downloaded: false,
      gameInstance: null,
      containerId: 'game-container'
    }
  },
  computed: {
    ...mapState(['score', 'lifes', 'gameOver', 'isMobile'])
  },
  async mounted() {
    const game = await import('@/game/game')
    this.downloaded = true
    this.$nextTick(() => {
      this.gameInstance = game.launch(this.containerId)
    })
  },
  destroyed() {
    this.gameInstance.destroy(false)
  },
  watch: {
    gameOver() {
      if (this.gameOver) this.$router.push({ name: 'Over' })
    }
  }
}
</script>

<style lang="scss" scoped>
.placeholder {
  font-size: 2rem;
  font-family: 'Courier New', Courier, monospace;
}

.board {
  position: relative;

  .score {
    right: 20px;
    position: absolute;

    &.isMobile {
      right: 50px;
    }
  }

  .lifes {
    left: 20px;
    position: absolute;

    &.isMobile {
      left: 50px;
    }
  }
}

.controls {
  text-align: center;
}
</style>

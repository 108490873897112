import Vue from 'vue'
import App from './App.vue'

import store from './store'
import router from './router'

import i18n from './i18n'

import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es.json'

import firebase from "firebase/app"
import "firebase/analytics"

import Api from './api'

localize('es', es)

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.prototype.$api = Api
Vue.config.productionTip = false

let firebaseConfig = {
  apiKey: "AIzaSyD9OqoKRrnTMHE9Ftpyo5LNjV0rWSxsi7Y",
  authDomain: "pinball-ray-ban.firebaseapp.com",
  databaseURL: "https://pinball-ray-ban.firebaseio.com",
  projectId: "pinball-ray-ban",
  storageBucket: "pinball-ray-ban.appspot.com",
  messagingSenderId: "141474872757",
  appId: "1:141474872757:web:ddd8be5c25db664a8bedfc",
  measurementId: "G-6Y45M62MGR"
}

firebase.initializeApp(firebaseConfig)
Vue.prototype.$analytics = firebase.analytics()

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')

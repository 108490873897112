<template lang="pug">
.instructions_container
  h1(v-if='this.ticket') {{ $t('instructions.h1') }}, {{ this.name }}!
  h1(v-else) {{ $t('instructions.h1') }}!
  h1(v-if='this.ticket') {{ $t('instructions.h1-registry') }}
  h2(v-if='this.ticket') {{ $t('instructions.h1-ticket') }}
  h2 {{ $t('instructions.h2.1') }} #[img(src="../assets/images/ArrowCircleLeft.svg", alt="left")] #[img(src="../assets/images/ArrowCircleRight.svg", alt="right")] {{ $t('instructions.h2.2') }}
  h3 {{ $t('instructions.h3') }}
  button(@click="next") {{ $t('instructions.button') }}
</template>

<script>
import Header from '../components/Header'
import { mapState } from 'vuex'

export default {
  name: 'Instructions',
  components: {
    Header
  },
  computed: {
    ...mapState(['name', 'ticket'])
  },
  methods: {
    next() {
      return this.$router.push({ name: 'PinBall' })
    }
  }
}
</script>

<template lang="pug">
game
</template>

<script>
import Game from '@/components/Game'
export default {
  name: 'pinball',
  components: { Game }
}
</script>

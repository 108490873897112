import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import Login from '../views/Login'
import Instructions from '../views/Instructions'
import PinBall from '../views/PinBall'
import Over from '../views/Over'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/instrucciones',
    name: 'Instructions',
    component: Instructions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pinball',
    name: 'PinBall',
    component: PinBall,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/fin-juego',
    name: 'Over',
    component: Over,
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if ((store.state.email == null || store.state.name == null) && !store.state.isGuest)
      next({ path: '/' })
    else
      next()
  } else {
    next()
  }
})

export default router

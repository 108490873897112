import axios from 'axios'

const client = axios.create({
  baseURL: process.env.VUE_APP_FUNCTIONS_BASE_URL
})

export default class Api {
  static async getCurrentPlayer(email) {
    try {
      const { data } = await client.post('/getPlayer', { email })
      return data
    } catch (error) {
      throw error.response
    }
  }

  static async savePlayer(player) {
    try {
      const { data } = await client.post('/updatePlayer', player)
      return data
    } catch (error) {
      throw error.response
    }
  }
}

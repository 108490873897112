<template lang="pug">
.login_container
  .switch
    h1 {{ $t("login.h1") }}
    p {{ $t("login.p") }} #[a(href='/legal/Bases_y_condiciones.pdf' target='_blank') {{ $t("login.link") }}]
  validation-observer(v-slot='{handleSubmit, errors,error}')
    form(@submit.prevent='handleSubmit(next)')
      validation-provider(rules='required|alpha_spaces', name='nombre', v-slot='{valid, dirty}')
        input(type='text', :placeholder='$t("login.name")', v-model='name', :class='{ invalid: dirty && !valid }')
      validation-provider(rules='required|email', name='correo', v-slot='{valid, dirty}')
        input(type='text', :placeholder='$t("login.email")', v-model='email', :class='{ invalid: dirty && !valid }')
      validation-provider(rules='required|numeric|length:10', name='telefono', v-slot='{valid, dirty}')
        input(type='text', :placeholder='$t("login.phone")', v-model='phone', :class='{ invalid: dirty && !valid }')
      validation-provider(rules='required|alpha_num|length:20', name='ticket', v-slot='{valid, dirty}')
        input(type='text', :placeholder='$t("login.ticket")', v-model='ticket', :class='{ invalid: dirty && !valid }')
      validation-provider(:rules='{ required: { allowFalse: false } }', name='términos y condiciones', class='bases' )
        input(type='checkbox' id='bases' v-model="terms" ) 
        label(for='bases') {{ $t("login.bases") }}
      div
        p(v-if='parseErrors(errors)', v-for='error in parseErrors(errors)', style='display: block;') {{ error }}
      button(type='submit') {{ $t("login.button") }}
  button(type='submit' @click="invited" class='invited') {{ $t("login.invited") }}
</template>

<script>
import Header from '../components/Header'
import { mapMutations } from 'vuex'

export default {
  name: 'Home',
  components: {
    Header
  },
  data() {
    return {
      name: null,
      email: null,
      phone: null,
      ticket: null,
      terms: false
    }
  },
  methods: {
    ...mapMutations(['SET_DATA', 'SET_GUEST']),
    parseErrors(errors) {
      return Object.values(errors)
        .map(element => element[0])
        .filter(error => error)
    },
    next() {
      this.SET_DATA({ name: this.name, email: this.email, phone: this.phone, ticket: this.ticket })
      return this.$router.push({ name: 'Instructions' })
    },
    invited() {
      this.SET_GUEST(true)
      return this.$router.push({ name: 'Instructions' })
    }
  },
  mounted() {
    if (process.env.NODE_ENV == 'development') {
      ;(this.name = 'Test user'), (this.email = 'test@wearetorque.com'), (this.phone = '5511112222'), (this.ticket = '0123456789qazwsxedcr')
    }
  }
}
</script>
